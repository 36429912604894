import Network from '@/services/network'
import {ref} from 'vue'


export default function handleActivities(){
    const network = new Network;
    const loading = ref(false)

    const fetchActivityList = (query) => {
        return network.api('get', `/procurement/organization-setting/activity` + query);
    }

    return {
        fetchActivityList,
        loading
    }
}