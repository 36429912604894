<template>
    <div>
        <div class="card">
            <div class="bg-blue-light px-1 rounded pb-2">
                <TitleButton
                  class="mt-1"
                  btnTitle="Go Back"
                  title="Edit Journal"
                  @onClickCloseButton="goToList"
                />
                <div class="row mt-1">
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label class="col-form-label">Date</label>
                        <input
                          class="form-control invoice-edit-input date-picker flatpickr-input flatpickr-mobile"
                          tabindex="1"
                          type="date"
                          placeholder=""
                          v-model="journal.date"
                        >
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                        <label class="col-form-label">Voucher No</label>
                        <div
                            class="input-group input-group-merge invoice-edit-input-group has-validation"
                            :class="{'is-invalid': isEmptyVoucher}"
                        >
                            <div class="input-group-text">
                                <span :class="{'text-danger': isEmptyVoucher}">{{ journal.voucher_prefix }}-</span>
                            </div>
                            <input
                                type="number"
                                min="1"
                                class="form-control invoice-edit-input"
                                placeholder=""
                                aria-describedby="validationVoucherNameFeedback"
                                v-model="journal.voucher_serial"
                                :class="{'is-invalid': isEmptyVoucher}"
                            >
                        </div>
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                      <label for="colFormLabel" class="col-form-label">PR / PO Reference</label>
                      <div class="input-group mb-3">
                        <input v-model="journal.txn_no" type="text" class="form-control" placeholder="PR / PO Referance">
                        <button @click="openPrPoModal()" class="btn btn-outline-secondary" type="button" id="button-addon2">Select</button>
                      </div>
                    </div>
                    <div class="cols-12 col-sm-6 col-lg-4 col-xxl-3">
                      <label for="colFormLabel" class="col-form-label">Tag Register</label>
                      <v-select
                        placeholder="Select Tag Register"
                        class="w-100"
                        :options="tagRegisters"
                        label="name"
                        :reduce="name => name.id"
                        v-model="journal.tag_register_id"
                      />
                    </div>
                </div>
            </div>

            <div class="card p-2 mt-3">
                <AddFormElement
                  class="mb-2"
                  :businesses="businesses"
                  :accountHeads="accountHeads"
                  :ngoabHeads="ngoabHeads"
                  :budgets="budgets"
                  :programs="programs"
                  :projects="projects"
                  :costCentres="costCentres"
                  :contactProfiles="contactProfiles"
                  :paymentDate="journal.date"
                  v-for="(item, index) in journal.ledgers"
                  :key="index"
                  :index="index"
                  :item="item"
                  @onClose="onClose"
                  @isLoading="isDataLoading = true"
                  @loaded="isDataLoading = false"
                />

                <div class="row me-1 mt-3">
                    <div class="col-12 col-sm-4 col-lg-8 mb-1 mb-sm-0">
                        <AddButton title="Add line" @onClickAdd="onClickAdd"/>
                    </div>
                    <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control text-end" :value="totalDebit"></div>
                    <div class="col-6 col-sm-4 col-lg-2"><input readonly type="number" class="form-control text-end" :value="totalCredit"></div>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="attachment" class="form-label fw-bold">Attachment</label>
                            <BlobFileViewer :attachments="journal.attachments" />
                            <FileUpload
                              :is-drag-active="true"
                              button-text="Upload Documents"
                              v-model="journal.attachments"
                            >
                            </FileUpload>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-12">
                        <div class="mb-2">
                            <label for="note" class="form-label fw-bold">Memo</label>
                            <textarea v-model="journal.note" placeholder="Memo" class="form-control" rows="2" id="note"></textarea>
                        </div>
                    </div>
                </div>

                <div class="mt-2">
                    <button
                      :disabled="!isDebitCreditEqual || loading"
                      @click="saveJournal"
                      class="btn btn-primary me-1"
                    >
                        Update
                    </button>
                    <button
                      @click="goToList"
                      class="btn btn-danger"
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <!-- PR PO Modal -->
            <PrPoModal
              :lists="prPoLists"
              ref="prPoModal"
              @selectedPrPo="selectedPrPo"
            />
            <Loader v-if="isDataLoading"/>
        </div>
        <VoucherValidationModal
          ref="voucherValidationModal"
          :voucherQuery="voucherQuery"
          @on-press-ok="setVoucherNumberAndDate(true)"
        />
    </div>
</template>

<script setup>
import handleJournal from '@/services/modules/journal'
import handleContact from '@/services/modules/contact'
import handleCBusinesses from '@/services/modules/businesses'
import {editVoucherNumber, generateQuery, generateVoucherNumber} from "@/services/utils/voucherNumberGenerator";
import {computed, inject, onMounted, watch, ref} from 'vue'
import TitleButton from '@/components/atom/TitleButton'
import AddFormElement from '@/components/molecule/ngo/journal/AddFormElement'
import AddButton from '@/components/atom/AddButton'
import {useRoute, useRouter} from "vue-router";
import handleProjects from "@/services/modules/procurement/project";
import handleNGOPrograms from "@/services/modules/ngo/program";
import handleCostCentres from "@/services/modules/ngo/costCentre";
import handleActivities from "@/services/modules/ngo/activity";
import handleFD6Projects from "@/services/modules/procurement/fd6";
import BlobFileViewer from "@/components/atom/BlobFileViewer";
import FileUpload from "@/components/atom/FileUpload";
import Loader from '@/components/atom/LoaderComponent'
import VoucherValidationModal from "@/components/molecule/company/voucher/VoucherValidationModal.vue";
import figureFormatter from "@/services/utils/figureFormatter";
import PrPoModal from '@/components/molecule/ngo/payment/PrPoModal'
import handleTagRegister from '@/services/modules/scm/tag-register'

const {fetchAccountHeads, editJournal, fetchJournal, loading} = handleJournal()
const {fetchContactProfiles} = handleContact()
const {fetchBusinessList} = handleCBusinesses()
const {fetchProjects} = handleProjects()
const {fetchProgramList} = handleNGOPrograms()
const {fetchCostCentreList} = handleCostCentres()
const {fetchActivityList} = handleActivities()
const {fetchFD6AccountsAll} = handleFD6Projects()
const {decimalFormat} = figureFormatter();
const {fetchTagRegisterList, fetchPrPoList} = handleTagRegister()

const showError =  inject('showError');
const showSuccess =  inject('showSuccess');
const accountHeads = ref([])
const saveNewLoader = ref(false)
const contactProfiles  = ref([])
const businesses  = ref([])
const projects = ref([])
const ngoabHeads = ref([])
const programs = ref([])
const costCentres = ref([])
const activities = ref([])
const budgets = ref([])
const isDataLoading = ref(false)
const isEmptyVoucher = ref(false);
const voucherValidationModal = ref(null);
const prPoLists = ref([])
const tagRegisters = ref([])
const prPoModal = ref(null);
const journal = ref({
  company_id:'',
  contact_profile_id: '',
  business_id: '',
  date: '',
  note: '',
  mop_references: 'mop',
  voucher_type: 'journal_voucher',
  voucher_prefix : '',
  voucher_serial : '',
  voucher_no : '',
  txn_no: null,
  tag_register_id: null,
  attachments: [],
  ledgers: []
})
const $route = useRoute();
const $router = useRouter();


//computed
const isLoading = computed(() => loading || saveNewLoader)
const start = computed(() => $route.query.start)
const end = computed(() => $route.query.end)
const general = computed(() => journal.value.ledgers)
const totalDebit = computed( () => {
  let total = 0;
  general.value.map(i => {
    if(i.debit) total += i.debit
  })
  return total
})
const totalCredit = computed( () => {
  let total = 0;
  general.value.map(i => {
    if(i.credit) total += i.credit
  })
  return total
})
const isDebitCreditEqual = computed( () => totalCredit.value === totalDebit.value)
const journalVoucherPrefix = computed( () => journal.value.voucher_prefix);
const journalVoucherSerial = computed( () => journal.value.voucher_serial);
const voucherQuery = computed(() => {
    return generateQuery($route.params.companyId, 'journal_voucher', 'general_journal')
});

//Watchers
watch( journalVoucherPrefix, () => {
    concatVoucher();
})

watch( journalVoucherSerial, () => {
    concatVoucher();
})

//methods
const concatVoucher = () =>{
    isEmptyVoucher.value = false;

    if(journal.value.voucher_serial === '') {
        isEmptyVoucher.value = true;
        return ;
    }

    let voucherSerial = decimalFormat(journal.value.voucher_serial.toString().replace(/[^0-9\.]+/g, ''))
    if(voucherSerial === '') {
        isEmptyVoucher.value = true;
    }
    journal.value.voucher_serial = voucherSerial;
    journal.value.voucher_no = `${journal.value.voucher_prefix}-${voucherSerial}`;
}

const goToList = () => {
  let params = {
    companyId: $route.params.companyId,
    moduleId: $route.params.moduleId,
    menuId: $route.params.menuId,
    pageId: $route.params.pageId
  };
  let query = {
    start: start.value,
    end: end.value,
  }

  let isAllVouchers = $route.query.type && true;
  $router.push({
    query: query,
    name: isAllVouchers ? 'voucher-list' :'journal-voucher-np',
    params: params
  })
}

const onClose = (index) => {
  journal.value.ledgers.splice(index, 1)
}

const onClickAdd = () => {
  journal.value.ledgers.push({
    account_head_id: null,
    debit: '',
    credit: '',
    note: '',
    tax_rate: null,
    taxable_amount: null
  })
}

const openPrPoModal = () => {
  prPoModal.value.toggleModal()
}
const selectedPrPo = (pr_po) => {
  journal.value.txn_no = pr_po;
}

const setVoucherNumberAndDate = (withoutDate = false) => {
    new Promise(async (resolve, reject) => {
        try {
            if (!withoutDate) {
                journal.value.date = new Date().toISOString().split('T')[0]
            }
            let voucher = await generateVoucherNumber(voucherQuery.value);
            journal.value.voucher_prefix = voucher.prefix;
            journal.value.voucher_serial = voucher.serial;
            resolve();
        } catch (err) {
            reject(err);
        }
    })
}

const resetForm = () => {
  journal.value = {
    company_id:'',
    date: '',
    note: '',
    mop_references: 'mop',
    voucher_type: 'journal_voucher',
    voucher_prefix: '',
    voucher_serial: '',
    ledgers: [
      {
        business_id: null,
        account_head_id: null,
        debit: null,
        credit: null,
        note: '',
        tax_rate: null,
        taxable_amount: null,
        project_id: null,
        donor_id: null,
        budget_master_id: null,
        project_account_id: null,
        budget_general_id: null,
        ngoab_head_id: null,
        program_id: null,
        cost_centre_id: null,
        contact_profile_id: null
      }
    ]
  }
}

const getFormData = () => {
  let formData = new FormData();
  let previous_file_ids = [];
  Object.keys(journal.value).forEach(i => {
    if(i !== 'ledgers' && i !== 'id' && i !== 'status' && i !== 'business_id' && i !== 'contact_profile_id' && i !== 'attachments' ) {
      formData.append(i, journal.value[i])
    }
  })

  let generalLedgerData = journal.value.ledgers.slice();
  generalLedgerData.map(ledger => {
    ledger.txn_no = journal.value.txn_no;
    ledger.tag_register_id = journal.value.tag_register_id;
  })

  formData.append('general_ledgers', JSON.stringify(generalLedgerData))
  formData.append("_method", "patch")
  if(journal.value.contact_profile_id) formData.append('contact_profile_id', journal.value.contact_profile_id)
  if(journal.value.attachments) {
    for(let i=0; i<journal.value.attachments.length; i++) {
      if(typeof journal.value.attachments[i] === 'object' && journal.value.attachments[i].hasOwnProperty('id')) {
        previous_file_ids.push(journal.value.attachments[i].id)
      } else {
        let key = `attachments[${i}]`;
        let value = journal.value.attachments[i];
        formData.append(key, value);
      }
    }
    if(previous_file_ids.length) {
      formData.append('previous_file_ids', JSON.stringify(previous_file_ids))
    }
  }
  return formData;
}

const saveJournal = async (redirect = false) => {
  //update Journal
  concatVoucher()
  journal.value.company_id = $route.params.companyId;
  let data = getFormData();
  if(redirect) {
    loading.value = true
  } else {
    saveNewLoader.value = true
  }
  try {
    let res = await editJournal(journal.value.id, data)
    if(!res.status) {
      showError(res.message)
    }
    if(res.status) {
      showSuccess(res.message)
      resetForm()
      if(redirect) goToList()
    }
  } catch (err) {
    if(!err.response) {
        showError('Something is wrong. Check your connectivity!!')
    }
    if(err.response && err.response.status !== 406) {
        showError(err.response.data?.message)
    }
    if(err.response.data.data && err.response.data.data.show_modal) {
        voucherValidationModal.value.openModal(err.response?.data.message)
    }
  } finally {
    loading.value = false
    saveNewLoader.value = false
  }
}

onMounted(async () => {
  isDataLoading.value = true;
  let voucher = null;
  let companyId = $route.params.companyId
  let contactId = '';
  let q = '';
  let companyQuery = '?company_id=' + companyId;
  let query = companyQuery + '&q=' + q + '&contact_id' + contactId;
  Promise.all([
    fetchAccountHeads(companyId, 'yes').then(res => {
      if(res.data){
        accountHeads.value = res.data
      }
    }),
    fetchFD6AccountsAll(companyQuery).then(res => {
      if(res.data){
        ngoabHeads.value = res.data
      }
    }),
    fetchProgramList(companyQuery).then(res => {
      if(res.data){
        programs.value = res.data;
      }
    }),
    fetchCostCentreList(companyQuery).then(res => {
      if(res.data) {
        costCentres.value = res.data
      }
    }),
    fetchActivityList(companyQuery).then(res => {
      if(res.data){
        activities.value = res.data
      }
    }),
    fetchProjects(companyQuery).then(res => {
      if(res.data) {
        projects.value = res.data
      }
    }),
    fetchContactProfiles(query).then(res => {
      if(res.data) {
        contactProfiles.value = res.data;
      }
    }),
    fetchBusinessList(companyQuery).then(res => {
      if(res.data) {
        businesses.value = res.data;
      }
    }),
    fetchJournal($route.params.journalId).then( res => {
        if(!res.status) {
          showError(res.message)
        }
        if(res.status) {
          journal.value = res.data
          voucher = editVoucherNumber(journal.value.voucher_no)
          journal.value.voucher_prefix = voucher.prefix;
          journal.value.voucher_serial = voucher.serial;
          journal.value.txn_no = res.data.ledgers.length > 0 ? res.data.ledgers[0].txn_no: null;
          journal.value.tag_register_id = res.data.ledgers.length > 0 ? res.data.ledgers[0].tag_register_id: null;
        }
      }),
    fetchPrPoList(companyQuery).then(res => {
      if(res.data) {
        prPoLists.value = res.data;
      }
    }),
    fetchTagRegisterList(companyQuery).then(res => {
      if(res.data) {
        tagRegisters.value = res.data;
      }
    }),
  ]).catch(err => {
    showError(err.message)
  }).finally(() => {
    isDataLoading.value = false;
  })
})
</script>

<style scoped>
.label-min-width {
        min-width: 80px
    }
</style>
