<template>
  <div v-if="isOpen" class="modal fade show" aria-labelledby="addNewCardTitle">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="row">
          <div class="col-12">
            <div class="d-flex justify-content-between px-1 my-1 align-item-center">
              <h3 class="mb-0">Select PR / PO Reference</h3>
              <button
                  @click="toggleModal"
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
              ></button>
            </div>
          </div>
        </div>
        <hr class="mt-0">

        <div class="pb-2">
          <!-- form -->
          <div class="row gy-2 px-2">
            <div class="cols-12 col-sm-12 col-lg-12 col-xxl-12">
              <label for="colFormLabel" class="col-form-label">Type</label>
              <v-select
                  placeholder="Select type"
                  class="w-100"
                  :options="types"
                  label="name"
                  :reduce="name => name.id"
                  v-model="type"
                  @option:selected="onSelectType"
              />
            </div>
            <div class="cols-12 col-sm-12 col-lg-12 col-xxl-12 text-center">
              <template v-if="listsPrPo.length">
                <table class="table table-bordered">
                  <tbody>
                  <tr v-for="listsPrPo in listsPrPo">
                    <td>{{ listsPrPo }}</td>
                    <td>
                      <button class="btn btn-primary btn-sm waves-light" @click="selectPrPo(listsPrPo)">Select</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </template>
              <template v-if="!listsPrPo.length && type">
                <strong>No Data found</strong>
              </template>
              <template v-if="!listsPrPo.length && !type">
                <strong>Please select type</strong>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref} from "vue";

const isOpen = ref(false);
const type = ref(null);
const listsPrPo = ref([]);
const types = ref([
  {
    id: 'pr',
    name: 'PR',
  }, {
    id: 'po',
    name: 'PO',
  }
]);

const props = defineProps({
  lists: {
    type: Object,
    default: []
  }
})

const $emit = defineEmits(['selectedPrPo'])

const selectPrPo = (prPo) => {
  $emit('selectedPrPo', prPo)
  toggleModal();
}
const onSelectType = () => {
  listsPrPo.value = props.lists[type.value]
}
const toggleModal = () => {
  isOpen.value = !isOpen.value
}
defineExpose({toggleModal})
</script>
<style>
.modal {
  display: block;
  background: #1c1d1c33
}
</style>
